<template>
  <div class="relative flex overflow-hidden rounded-lg h-44">
    <img
      :src="location.images.full"
      class="object-cover w-full h-full cursor-pointer"
      @click="
        $router.push({ name: 'location', params: { id: location.id, transition: 'slide-forward' } })
      "
    />
    <div class="absolute top-0 flex-grow">
      <div class="px-3 py-2 leading-tight text-white bg-gray-900 bg-opacity-90 rounded-br-md">
        <div>{{ location.title }}</div>
      </div>
    </div>
    <button
      class="absolute flex items-center justify-center w-10 h-10 text-gray-200 bg-gray-800 rounded-full focus:outline-none text-1xl bottom-3 right-3"
      @click="deleteOverlayVisible = true"
    >
      <i class="fa fa-heart"></i>
    </button>
    <transition name="fade">
      <div
        v-if="deleteOverlayVisible"
        class="absolute top-0 flex flex-col items-center justify-center w-full h-full bg-gray-800 bg-opacity-80"
      >
        <div class="text-gray-100 text-md">Fjerne fra favoritter?</div>
        <div class="mt-2 space-x-2">
          <button
            class="w-20 py-2 bg-gray-200 rounded-full focus:outline-none"
            @click="deleteOverlayVisible = false"
          >
            Jepp
          </button>
        </div>
        <button
          class="absolute flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full focus:outline-none text-1xl top-3 right-3 bg-opacity-70"
          @click="deleteOverlayVisible = false"
        >
          <i class="fal fa-xmark"></i>
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
import { ref } from 'vue'
export default {
  props: {
    location: Object,
  },
  setup() {
    const deleteOverlayVisible = ref(false)
    return {
      deleteOverlayVisible,
    }
  },
}
</script>

<style></style>
