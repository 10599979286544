<template>
  <card-container backButton closeButton>
    <div class="flex flex-col p-6 mt-10">
      <div class="text-3xl text-center">
        <div v-if="tabIndex == 0">Favoritter (4)</div>
        <div v-if="tabIndex == 1">Besøkt (0)</div>
        <div v-if="tabIndex == 2">Delt (0)</div>
      </div>
      <div class="flex mt-4 text-sm">
        <button
          class="flex-1 px-4 py-2 rounded-l-full ring-1 ring-gray-300 focus:outline-none"
          :class="{ 'bg-gray-300': tabIndex == 0 }"
          @click="tabIndex = 0"
        >
          Favoritter
        </button>
        <button
          class="flex-1 px-4 py-2 bg-gray-100 ring-1 ring-gray-300 focus:outline-none"
          :class="{ 'bg-gray-300': tabIndex == 1 }"
          @click="tabIndex = 1"
        >
          Besøkt
        </button>
        <button
          class="flex-1 px-4 py-2 bg-gray-100 rounded-r-full ring-1 ring-gray-300 focus:outline-none"
          :class="{ 'bg-gray-300': tabIndex == 2 }"
          @click="tabIndex = 2"
        >
          Delt
        </button>
      </div>

      <!-- Favourites -->
      <div v-if="tabIndex == 0" class="mt-5 space-y-2">
        <location-list-item
          v-for="location in locations"
          :key="location.id"
          :location="location"
        ></location-list-item>
      </div>

      <!-- Visited -->
      <div v-if="tabIndex == 1" class="flex-grow mt-12 text-center">
        <div class="mt-4 text-8xl">☑️</div>
        <div class="mt-4">
          Her vil du få en liste med alle stedene du har besøkt, sortert etter dato.
          <br /><br />
          For å registrere et besøk klikker du på ☑️ når du er inne på en plass.
        </div>
      </div>

      <!-- Shared -->
      <div v-if="tabIndex == 2" class="flex-grow mt-12 text-center">
        <div class="mt-4 text-8xl">🎁</div>
        <div class="mt-4">
          Her vil du se stedene du har delt
        </div>
        <div class="mt-12 text-center">
          <button class="px-6 py-4 rounded-full ring-gray-300 ring-1">
            Del din første plass nå
          </button>
        </div>
      </div>
    </div>
  </card-container>
</template>
<script>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import CardContainer from '../components/CardContainer.vue'
import LocationListItem from '../components/LocationListItem.vue'

export default {
  components: { CardContainer, LocationListItem },
  setup() {
    const store = useStore()

    const tabIndex = ref(0)

    const signOut = () => {}

    return {
      signOut,
      tabIndex,
      locations: computed(() => store.state.locations),
    }
  },
}
</script>
